let tipsTxt = "您当前浏览器版本过低，为保证更好的浏览体验，请用Google Chrome、Safari、火狐、IE11等其他浏览器浏览。";
if($("body").hasClass("enSite")){
    tipsTxt = "The current browser version is too early. To ensure better browsing experience, use other browsers, such as Google Chrome, Safari, Firefox, or Internet Explorer 11."
}
var flag = true;
var canChange = true;
var pt = pt1 = pt2 = null;
var canTab = true;
function changeSize(){
    var w = $(window).width();
    var h = $(window).height();
    if(h<500){
        h = 500;
    }
    $(".homePage").css("height",h);
}
$(".silder").each(function(){
    var size = $(this).find("li").size();
    var n = 1;
    $(this).find("li:first").addClass("curt");
    $(this).find(".pageTab").find(".num").html(1);
    $(this).find(".pageTab").find(".all").html(size);
});
changeSize();
moveCursor(0);
$(window).resize(function () {
    changeSize();
    moveCursor();
})



function moveCursor(n) {
    if($(".pageBottom .chost").size() == 0){
        return false;
    }
    var index = $(".pageBottom .chost").index();
    var l = $(".pageBottom li").eq(index).offset().left - $(".homeCont").offset().left;
    $(".cursor").css("left",l);
}

function callBack(){
    var id = window.location.href.split("?id=")[1];
   // console.log(id);
    if(id){
        window.mySwiper.slideTo(Number(id),0);
    }
}


$(".touzi .next").click(function(){
    if(canChange){
        canChange = false;
        var target = $(this).parents(".silder");
        var size = target.find("li").size();
        //var n = $(this).html();
        var index = target.find(".curt").index();
        target.find("li").eq(index).addClass("outRight");
        index++;
        if(index == size){
            index = 0;
        }
        target.find(".num").html(index+1);
        // $(this).html(index+1);
        setTimeout(function(){
            canChange = true;
            target.find("li").eq(index).addClass("curt").siblings().attr("class",'');
        },600);
    }
});

$(".touzi .prev").click(function(){
    if(canChange){
        canChange = false;
        var target = $(this).parents(".silder");
        var size = target.find("li").size();
        //var n = $(this).html();
        var index = target.find(".curt").index();
        target.find("li").eq(index).addClass("outLeft");
        index--;
        if(index == -1){
            index = size - 1;
        }
        target.find(".num").html(index+1);
        // $(this).html(index+1);
        setTimeout(function(){
            canChange = true;
            target.find("li").eq(index).addClass("curt").siblings().attr("class",'');
        },600);
    }
});

$(".pageBottom li").hover(function(){
    if($(this).hasClass("chost")){
        return false;
    }
    clearTimeout(pt1);
    var that = $(this);
    pt = setTimeout(function(){
        var index = that.index();
        // $(".pageBottom .chost").addClass("on").removeClass("chost");
        that.addClass("chost").siblings().removeClass("chost");
        moveCursor();
    },200);
},function(){

    if($(".pageBottom .on").size()){
        clearTimeout(pt);

        //$(".pageBottom li").removeClass("on");

        pt1 = setTimeout(function(){
            $(".pageBottom li.on").addClass("chost").siblings().removeClass("chost");
            moveCursor();
        },200)
    }
})

$(".pageBottom li").click(function(){
    if(!$(this).hasClass("on") && flag){
        flag = false;
        var index = $(this).index();
        runFristOne();
        $(".silder").eq(index).addClass("on").siblings().removeClass("on");
        $(this).addClass("on").siblings().removeClass("on");
        setTimeout(function(){
            flag = true;
        },800);
    }
});

// setTimeout(function () {
//    // runFun();
// },1000)

function runFun() {
    $(".pageBottom li").each(function () {
        var h = $(this).find("h3");
        var n = h.attr("data-num");
        runNum(h,n);
    })
}

function clearRun(){
   // clearTimeout(pt2);
    $(".pageBottom li").each(function () {
        var h = $(this).find("h3");
        var n = h.attr("data-num");
        h.html(n);
        setTimeout(function(){
            h.html(0);
        },60)
    });
}

function runNum(dom,num){
    //console.log(num);
    run();
    function run(){
        var html = parseInt(dom.html());
        // console.log(dom.parent().index(),html,num)
        if(html < num){
            html++;
            dom.html(html);
            setTimeout(function(){
                run();
            },50)
        }
    }
}


$(".tremListBox .next").click(function(){
    if(canTab){
        canTab = false;
        var index = $(".teamBoxLists .on").index();
        var size = $(".teamBoxLists ul").size();
        index++;
        if(index >= size){
            index = 0;
        }
        $(".teamBoxLists ul").eq(index).show().css(rtunrnNum('100%'));
        setTimeout(()=>{
            $(".teamBoxLists ul").eq(index).addClass("time").css(rtunrnNum('0%'));
            $(".teamBoxLists .on").addClass("time").css(rtunrnNum('-100%'))
            setTimeout(()=>{
                $(".teamBoxLists ul").removeClass("time");
                $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on").hide();
                canTab = true;
            },800);
        },20);
        // $(".teamBoxLists ul").eq(index).show().css("left","100%").animate({
        //     "left":"0%"
        // },300);
        // $(".teamBoxLists .on").animate({
        //     "left":"-100%"
        // },300,function () {
        //     $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on").hide();
        //     canTab = true;
        // })
        // $(".teamBoxLists ul").eq(index).show().css({"opacity":"0","z-index":"-1"}).animate({
        //     "opacity":1,
        //     "z-index":"5"
        // },800);
        // $(".teamBoxLists .on").animate({
        //     "opacity":"0",
        //     "z-index":"-1"
        // },300,function () {
        //     $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on").hide();
        //     canTab = true;
        // })
        // $(".teamBoxLists .on").fadeOut(300);
        // setTimeout(()=>{
        //     $(".teamBoxLists ul").eq(index).fadeIn(800);
        //     setTimeout(()=>{
        //         $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on");
        //         canTab = true;
        //     },400);
        // },400);
        // $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on");
        // setTimeout(()=>{
        //     canTab = true;
        // },300);
    }
});
$(".tremListBox .prev").click(function(){
    if(canTab){
        canTab = false;
        var index = $(".teamBoxLists .on").index();
        var size = $(".teamBoxLists ul").size();
        index--;
        if(index <0){
            index = size-1;
        }

        $(".teamBoxLists ul").eq(index).show().css(rtunrnNum('-100%'));
        setTimeout(()=>{
            $(".teamBoxLists ul").eq(index).addClass("time").css(rtunrnNum('0%'));
            $(".teamBoxLists .on").addClass("time").css(rtunrnNum('100%'));
            setTimeout(()=>{
                $(".teamBoxLists ul").removeClass("time");
                $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on").hide();
                canTab = true;
            },800);
        },20);
        // $(".teamBoxLists ul").eq(index).show().css("left","-100%").animate({
        //     "left":"0%"
        // },300);
        // $(".teamBoxLists .on").animate({
        //     "left":"100%"
        // },300,function () {
        //     $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on").hide();
        //     canTab = true;
        // })
        // $(".teamBoxLists ul").eq(index).show().css({"opacity":"0","z-index":"-1"}).animate({
        //     "opacity":1,
        //     "z-index":"5"
        // },800);
        // $(".teamBoxLists .on").animate({
        //     "opacity":"0",
        //     "z-index":"-1"
        // },300,function () {
        //     $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on").hide();
        //     canTab = true;
        // })
        // $(".teamBoxLists .on").fadeOut(300);
        // setTimeout(()=>{
        //     $(".teamBoxLists ul").eq(index).fadeIn(800);
        //     setTimeout(()=>{
        //
        //     },400);
        // },400);
        // $(".teamBoxLists ul").eq(index).addClass("on").siblings().removeClass("on");
        // setTimeout(()=>{
        //     canTab = true;
        // },300);
    }
});


function rtunrnNum(n){
    let obj ={
        "transform":`translateX(${n})`,
        "-webkit-transform":`translateX(${n})`,
        "-moz-transform":`translateX(${n})`,
        "-ms-transform":`translateX(${n})`,
        "-o-transform":`translateX(${n})`,
    }
    return obj;
}

function isIE() {
    if(!!window.ActiveXObject || "ActiveXObject" in window){
        //document.body.innerHTML = '<h1>你的浏览器已过时，请更新你的浏览器</h1>';
        return true;
    }else{
        return false;
    }
}

function IEVersion() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if(isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if(fIEVersion == 7) {
            return 7;
        } else if(fIEVersion == 8) {
            return 8;
        } else if(fIEVersion == 9) {
            return 9;
        } else if(fIEVersion == 10) {
            return 10;
        } else {
            return 6;//IE版本<=7
        }
    } else if(isEdge) {
        return 'edge';//edge
    } else if(isIE11) {
        return 11; //IE11
    }else{
        return -1;//不是ie浏览器
    }
}

if(isIE()){
    $("body").addClass("ieSitePage");
    if(IEVersion()<10){
       apptips();
    }
}else{
   // $("body").addClass("langSitePage");
}


function apptips() {
    $("body").append(`<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;z-index: 1000;background: rgba(0,0,0,0.7);"><p style="position: absolute; width: 60%;text-align: center;font-size: 18px; line-height:40px;color: #fff; word-break: break-word;left: 20%; top: 20%;">${tipsTxt}</p></div>`);
}


function runFristOne(){
    $(".silder").each(function(){
        $(this).find(".box li").eq(0).addClass("curt").siblings().removeClass("curt");
        $(this).find(".pageTab .num").html(1);
    })
}